import React, { useState } from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Box, Container, Grid, Button } from "@material-ui/core"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { vscDarkPlus as syntaxTheme } from "react-syntax-highlighter/dist/esm/styles/prism"
import rehypeRaw from "rehype-raw"
import svgbg from "../new/svg-bg.svg"
import dataengg from "../new/off-images/data.svg"

import Layout from "../components/layout"
import Heading from "../components/heading"
import ServicesModal from "../components/modals/services"

export const query = graphql`
  query ServiceQuery($id: String!) {
    strapiService(id: { eq: $id }) {
      heading
      content
      id
      image {
        publicURL
      }
      service_item {
        id
        content
        position
        title
      }
      description
      keywordsMeta
    }
  }
`

function Service({ data }) {
  const service = data.strapiService
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Layout
      siteTitle={service.heading}
      description={service.description}
      keywords={service.keywordsMeta}
      ogImage={service.image?.publicURL}
    >
      <ServicesModal handleClose={handleClose} open={open} />
      <Heading title={service.heading} />
      <section>
        <Container style={{ padding: "100px 0px" }}>
          <Grid
            // container
            // spacing={10}
            className={classes.gridSpacing}
            // sx={{ spacing: { xs: 0, md: 10 } }}
            // alignItems="center"
            // justifyContent="center"
          >
            <Grid item md={6} className={classes.orderFirst}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <img
                  src={service.image?.publicURL}
                  width={300}
                  height={300}
                  // style={{ filter: `grayscale(1)` }}
                  alt={service.heading}
                />
              </div>
            </Grid>
            <Grid item md={6} className={classes.orderSecond}>
              <div style={{ padding: "20px" }}>
                <Typography variant="subtitle1" align="justify">
                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    children={service.content}
                    transformImageUri={uri =>
                      uri.startsWith("http")
                        ? uri
                        : `${process.env.GATSBY_IMAGE_BASE_URL}${uri}`
                    }
                    className="dynamicContent"
                    escapeHtml={false}
                    components={{
                      code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || "")
                        return !inline && match ? (
                          <SyntaxHighlighter
                            children={String(children).replace(/\n$/, "")}
                            style={syntaxTheme}
                            language={match[1]}
                            PreTag="div"
                            {...props}
                          />
                        ) : (
                          <code className={className} {...props}>
                            {children}
                          </code>
                        )
                      },
                    }}
                  />
                </Typography>
                {service.service_item.map((serviceDetail, key) => {
                  return (
                    <div style={{ marginLeft: 40 }}>
                      <Typography variant="subtitle1" color="textPrimary">
                        <b>{serviceDetail.title}</b>
                      </Typography>
                      <Typography variant="subtitle1" align="justify">
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={serviceDetail.content}
                          transformImageUri={uri =>
                            uri.startsWith("http")
                              ? uri
                              : `${process.env.GATSBY_IMAGE_BASE_URL}${uri}`
                          }
                          className="dynamicContent"
                          escapeHtml={false}
                          components={{
                            code({
                              node,
                              inline,
                              className,
                              children,
                              ...props
                            }) {
                              const match = /language-(\w+)/.exec(
                                className || ""
                              )
                              return !inline && match ? (
                                <SyntaxHighlighter
                                  children={String(children).replace(/\n$/, "")}
                                  style={syntaxTheme}
                                  language={match[1]}
                                  PreTag="div"
                                  {...props}
                                />
                              ) : (
                                <code className={className} {...props}>
                                  {children}
                                </code>
                              )
                            },
                          }}
                        />
                      </Typography>
                    </div>
                  )
                })}
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <Box textAlign="center" pb={10}>
        <Box py={3}>
          <Typography variant="h5" color="primary">
            <b>For Enquiries About Our Services</b>
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleOpen}
        >
          CONTACT US
        </Button>
      </Box>
    </Layout>
  )
}

const useStyles = makeStyles(theme => ({
  orderFirst: {
    [theme.breakpoints.up("md")]: {
      order: 1,
    },
  },
  orderSecond: {
    [theme.breakpoints.up("md")]: {
      order: 2,
    },
  },
  gridSpacing: {
    [theme.breakpoints.down("md")]: {
      margin: "0px",
    },
    [theme.breakpoints.up("md")]: {
      margin: "-40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}))

export default Service
